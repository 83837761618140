import { api } from './api';

export const tareaService = {
  getTareas,
  putTarea,
  getTareaBitacora,
  postTareaBitacora,
  getTareaEjecutores,
  getTareaIndicadores,
  getGraficoTortaTarea,
  getGraficoHistorialTarea,
  getCorreoTarea
};

function getCorreoTarea(data) {
  return api.post(
    api.tx.getCorreoTarea, 
    data
  ).then(data => {
    return data;
  });
}

function getGraficoHistorialTarea(data) {
  return api.post(
    api.tx.getGraficoHistorialTarea, 
    data
  ).then(data => {
    return data;
  });
}

function getGraficoTortaTarea(data) {
  return api.post(
    api.tx.getGraficoTortaTarea, 
    data
  ).then(data => {
    return data;
  });
}

function getTareaIndicadores(data) {
  return api.post(
    api.tx.getTareaIndicadores, 
    data
  ).then(data => {
    return data;
  });
}

function getTareas(data) {
  return api.post(
    api.tx.getTareas, 
    data
  ).then(data => {
    return data;
  });
}

function putTarea(data) {
  return api.post(
    api.tx.putTarea, 
    data
  ).then(data => {
    return data;
  });
}

function getTareaBitacora(data) {
  return api.post(
    api.tx.getTareaBitacora, 
    data
  ).then(data => {
    return data;
  });
}

function postTareaBitacora(data) {
  return api.post(
    api.tx.postTareaBitacora, 
    data
  ).then(data => {
    return data;
  });
}

function getTareaEjecutores(data){
  return api.post(
    api.tx.getTareaEjecutores, 
    data
  ).then(data => {
    return data;
  });
}